<template>
  <div class="comicReader oepnX">
    <div class="content" v-if="!isLoading">
      <div class="atlas" v-if="currentWorks" @click="popupShow = true">
        <div
          class="atlasItem"
          v-for="itemUrl in currentWorks.contents[itemIndex]['urlSet']"
          :key="itemUrl"
        >
          <ImgDecypt
            :isLaz="false"
            v-if="itemUrl"
            :src="itemUrl"
            :class="{ previewImg: !loadImg }"
            @load="onLoad"
          ></ImgDecypt>
        </div>
        <!-- <div class="advertising" v-if="advInfo" @click.stop="goAdv(advInfo)">
                    <ImgDecypt class="advImg" :key="advInfo.cover" :src="advInfo.cover" />
                </div> -->
        <div class="advertising">
          <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">
            <van-swipe-item
              v-for="(item, index) in advInfo"
              :key="index"
              @click="goAdv(item)"
            >
              <ImgDecypt class="advImg" :src="item.cover" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
    <Loading v-else />
    <!--        <van-overlay :show="popupShow" @click="popupShow = false">-->
    <!--            <van-popup v-model="popupShow" :overlay="false" position="top" :style="{ height: '44px',background:'rgb(27,22,76)' }">-->
    <!--                <div @click.stop>-->
    <!--                    <HeadBar @click.stop :titleName="itemData && itemData.name" titleLocation="left"></HeadBar>-->
    <!--                </div>-->
    <!--            </van-popup>-->
    <!--            <van-popup v-model="popupShow" :overlay="false" position="bottom" :style="{ height: '59px',background:'rgb(27,22,76)' }">-->
    <!--                <div class="bottomBar" @click.stop>-->
    <!--                    <div class="up">-->
    <!--                        <img src="@/assets/png/leftUp.png">-->
    <!--                        <span>上一篇</span>-->
    <!--                    </div>-->
    <!--                    <div class="down">-->
    <!--                        <span>下一篇</span>-->
    <!--                        <img src="@/assets/png/rightDown.png">-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </van-popup>-->
    <!--        </van-overlay>-->

    <van-overlay :show="popupShow" @click="popupShow = false">
      <van-popup
        v-model="popupShow"
        :overlay="false"
        position="top"
        :style="{ height: '44px', background: 'rgb(255,255,255)' }"
      >
        <div @click.stop v-if="currentWorks">
          <div class="headBar">
            <div class="leftIcon" @click="$router.go(-1)">
              <img src="@/assets/png/left.png" />
            </div>
            <div class="headTitle" style="'justify-content':'left';">
              <!-- <span>{{ currentWorks.contents[itemIndex].name }}</span> -->
              <span>第{{ currentWorks.contents[itemIndex].name }}话</span>
            </div>
<!--            <div-->
<!--              class="fictionCollect"-->
<!--              v-if="currentWorks && !currentWorks.hasCollected"-->
<!--              @click="collectionWorks"-->
<!--            >-->
<!--              <img src="@/assets/png/fictionCollect2.png" />-->
<!--            </div>-->

<!--            <div-->
<!--              class="fictionCollect"-->
<!--              v-else-if="currentWorks"-->
<!--              @click="cancelCollectionWorks"-->
<!--            >-->
<!--              <img src="@/assets/png/fictionCollect2Active.png" />-->
<!--            </div>-->

            <div class="fictionSelect" @click="onChapter">
              <img src="@/assets/png/fictionSelect2.png" />
            </div>
          </div>
        </div>
      </van-popup>
      <!-- <van-popup
        v-model="popupShow"
        :overlay="false"
        position="bottom"
        :style="{ height: '109px', background: 'none' }"
      >
        <div class="Barrage" @click.stop>
          <div class="openBarrage">
            <img src="@/assets/png/Barrage.png" />
          </div>
          <div class="sendBarrage">
            <img src="@/assets/png/oBarrage.png" />
          </div>
        </div>
      </van-popup> -->
      <van-popup
        v-model="popupShow"
        :overlay="false"
        position="bottom"
        :style="{ height: '59px', background: 'rgb(255,255,255)' }"
      >
        <div class="bottomBar" @click.stop>
          <div class="up" @click="upWorks">
            <img src="@/assets/png/leftUp.png" />
            <span>上一章</span>
          </div>
          <div class="down" @click="downWorks">
            <span>下一章</span>
            <img src="@/assets/png/rightDown.png" />
          </div>
        </div>
      </van-popup>
    </van-overlay>

    <!-- 选章节 -->
    <van-popup
      v-model="chapterShow"
      position="right"
      :style="{
        height: '100%',
        width: '210px',
        background: 'rgb(255,255,255)',
      }"
    >
      <div class="chapterContent" v-if="currentWorks">
        <div class="chapterTitle">
          <span>{{ currentWorks.title }}</span>
        </div>
        <div class="chapterDivision">
          <div class="name">
            <span
              >共{{ currentWorks.contents.length }}章
              {{
                currentWorks.updatedDesc.slice(
                  0,
                  currentWorks.updatedDesc.indexOf("已完结") + 3
                )
              }}</span
            >
          </div>
          <div class="btn" @click="chapterSort">
            <img src="@/assets/png/sort.png" />
            <span v-if="sorting">倒序</span>
            <span v-else>升序</span>
          </div>
        </div>
        <div class="chapterList">
          <div
            class="chapterItem"
            v-for="(item, index) in currentWorks.contents"
            :key="index"
            @click="jumpWorks(index)"
          >
            <span :class="{ activeName: index == itemIndex }">{{
              item.episodeNumber + "-" + item.name
            }}</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import ImgDecypt from "@/components/ImgDecypt";
import { doCollect, douUcollect } from "@/api/video";
import { AdType, getAdItem, jumpAdv } from "@/utils/getConfig";
import { addVideoHistory, queryHistoryId } from "@/utils";

export default {
  name: "ComicReader",
  components: {
    ImgDecypt,
    Loading,
  },
  data() {
    return {
      currentWorks: null,
      show: false,
      isLoading: true,
      loadImg: false,
      popupShow: false,
      chapterShow: false,
      itemIndex: 0,
      sorting: true,
      advInfo: null,
      currentEpisode: 0, // 存储当前集数
    };
  },
  beforeDestroy() {
    // 判断集数
    // this.selectEpisode(this.currentEpisode);
    this.$store.dispatch('reader/setMaxEpisode', { novelId: this.currentWorks.id, maxEpisode: this.currentEpisode });
    console.log(this.$store.state.reader.novelId, this.currentEpisode);
  },
  mounted() {
    let _this = this;
    this.isLoading = true;
    let currentWorks = this.$store.getters.currentWorks;

    let query = this.$route.query;
    if (query) {
      let itemIndex = query.itemIndex;
      this.currentEpisode = itemIndex;
      if (!itemIndex) {
        itemIndex = 0;
      }
      // vuex 获取集数
      // this.currentEpisode = this.$store.state.reader.maxEpisodes[currentWorks.id];

      let itemContents = currentWorks.contents[itemIndex];
      //vip
      if (itemContents.listenPermission == 0 && !currentWorks.hasPaid) {
        if (!_this.$store.getters.isVip) {
          _this.$bus.$emit("vipPopup", {
            state: 1,
            videoType: currentWorks.kind,
            id: currentWorks.id,
            closeBtn: () => {
              _this.$bus.$emit("closeVipPopup");
            },
            buySuccess: () => {
              currentWorks.hasPaid = true;
              _this.itemIndex = itemIndex;
              this.$bus.$emit("closeVipPopup");
              // console.log(123123)
            },
          });
          return;
        }
      }
      //金豆收费
      if (itemContents.listenPermission == 1 && !currentWorks.hasPaid) {
        if (!itemContents.hasPaid) {
          _this.$bus.$emit("vipPopup", {
            state: 2,
            goldenNum: currentWorks.price,
            videoType: currentWorks.kind,
            id: currentWorks.id,
            closeBtn: () => {
              _this.$bus.$emit("closeVipPopup");
            },
            buySuccess: () => {
              currentWorks.hasPaid = true;
              _this.itemIndex = itemIndex;
              this.$bus.$emit("closeVipPopup");
              // console.log(123123)
            },
          });
          return;
        }
      }
      _this.currentWorks = currentWorks;
      // console.log(_this.currentWorks)
      _this.itemIndex = itemIndex;
      this.isLoading = false;
    }
    let advList = getAdItem(AdType.CREATION_CENTER);
    if (advList.length > 0) {
      this.advInfo = advList;
    }
  },
  methods: {
    // selectEpisode(index) {
    //   let maxEpisode = Math.max(index, this.maxEpisode);
    //   this.maxEpisode = maxEpisode;
    //   this.currentWorks.historyIndex = maxEpisode;
    //   addVideoHistory(this.currentWorks);
    //   console.log(index, "大于小", maxEpisode);
    // },
    onLoad() {
      this.loadImg = true;
    },
    onChapter() {
      this.popupShow = false;
      this.chapterShow = true;
    },
    jumpWorks(index) {
      let _this = this;
      let itemContents = _this.currentWorks.contents[index];
      //vip
      if (itemContents.listenPermission == 0 && !this.currentWorks.hasPaid) {
        if (!_this.$store.getters.isVip) {
          _this.$bus.$emit("vipPopup", {
            state: 1,
            videoType: this.currentWorks.kind,
            id: this.currentWorks.id,
            closeBtn: () => {
              _this.$bus.$emit("closeVipPopup");
            },
            buySuccess: () => {
              this.currentWorks.hasPaid = true;
              this.itemIndex = index;
              this.$bus.$emit("closeVipPopup");
              // console.log(123123)
            },
          });
          return;
        }
      }
      //金豆收费
      if (itemContents.listenPermission == 1 && !this.currentWorks.hasPaid) {
        if (!itemContents.hasPaid) {
          _this.$bus.$emit("vipPopup", {
            state: 2,
            goldenNum: this.currentWorks.price,
            videoType: this.currentWorks.kind,
            id: this.currentWorks.id,
            closeBtn: () => {
              _this.$bus.$emit("closeVipPopup");
            },
            buySuccess: () => {
              this.currentWorks.hasPaid = true;
              this.itemIndex = index;
              this.$bus.$emit("closeVipPopup");
              // console.log(123123)
            },
          });
          return;
        }
      }
      this.itemIndex = index;

      console.log(this.currentWorks, "+++++++++++++++++", index);
      this.currentEpisode = this.itemIndex;
      this.currentWorks.historyIndex = this.itemIndex;
      addVideoHistory(this.currentWorks);
    },
    upWorks() {
      let _this = this;
      //上一个章节
      if (this.itemIndex == 0) {
        this.$toast({
          message: "已经是第一篇",
          position: "top",
        });
      } else {
        let itemIndex = Number(_this.itemIndex) - 1;
        let itemContents = _this.currentWorks.contents[itemIndex];
        //vip
        if (itemContents.listenPermission == 0 && !this.currentWorks.hasPaid) {
          if (!_this.$store.getters.isVip) {
            _this.$bus.$emit("vipPopup", {
              state: 1,
              videoType: this.currentWorks.kind,
              id: this.currentWorks.id,
              closeBtn: () => {
                _this.$bus.$emit("closeVipPopup");
              },
              buySuccess: () => {
                this.currentWorks.hasPaid = true;
                _this.itemIndex = itemIndex;
                this.$bus.$emit("closeVipPopup");
                // console.log(123123)
              },
            });
            return;
          }
        }
        //金豆收费
        if (itemContents.listenPermission == 1 && !this.currentWorks.hasPaid) {
          if (!itemContents.hasPaid) {
            _this.$bus.$emit("vipPopup", {
              state: 2,
              goldenNum: this.currentWorks.price,
              videoType: this.currentWorks.kind,
              id: this.currentWorks.id,
              closeBtn: () => {
                _this.$bus.$emit("closeVipPopup");
              },
              buySuccess: () => {
                this.currentWorks.hasPaid = true;
                _this.itemIndex = itemIndex;
                this.$bus.$emit("closeVipPopup");
                // console.log(123123)
              },
            });
            return;
          }
        }
        _this.itemIndex = itemIndex;
      }
      this.currentEpisode--;
      this.currentWorks.historyIndex = _this.itemIndex;
      addVideoHistory(this.currentWorks);
    },
    downWorks() {
      let _this = this;
      //下一个章节
      if (_this.itemIndex == _this.currentWorks.contents.length - 1) {
        _this.$toast({
          message: "已经是最后一篇",
          position: "top",
        });
      } else {
        let itemIndex = Number(_this.itemIndex) + 1;
        let itemContents = _this.currentWorks.contents[itemIndex];
        //vip
        if (itemContents.listenPermission == 0 && !this.currentWorks.hasPaid) {
          if (!_this.$store.getters.isVip) {
            _this.$bus.$emit("vipPopup", {
              state: 1,
              videoType: this.currentWorks.kind,
              id: this.currentWorks.id,
              closeBtn: () => {
                _this.$bus.$emit("closeVipPopup");
              },
              buySuccess: () => {
                this.currentWorks.hasPaid = true;
                _this.itemIndex = itemIndex;
                this.$bus.$emit("closeVipPopup");
                // console.log(123123)
              },
            });
            return;
          }
        }
        //金豆收费
        if (itemContents.listenPermission == 1 && !this.currentWorks.hasPaid) {
          if (!itemContents.hasPaid) {
            _this.$bus.$emit("vipPopup", {
              state: 2,
              goldenNum: this.currentWorks.price,
              videoType: this.currentWorks.kind,
              id: this.currentWorks.id,
              closeBtn: () => {
                _this.$bus.$emit("closeVipPopup");
              },
              buySuccess: () => {
                this.currentWorks.hasPaid = true;
                _this.itemIndex = itemIndex;
                this.$bus.$emit("closeVipPopup");
                // console.log(123123)
              },
            });
            return;
          }
        }
        _this.itemIndex = itemIndex;
      }
      this.currentEpisode++;
      this.currentWorks.historyIndex = _this.itemIndex;
      addVideoHistory(this.currentWorks);
    },
    chapterSort() {
      //章节排序
      this.sorting = !this.sorting;
      this.currentWorks.contents.reverse();
      this.itemIndex = this.currentWorks.contents.length - 1 - this.itemIndex; // 更新选中的元素
    },
    async collectionWorks() {
      /**
       * 收藏作品
       */

      let req = {
        kind: this.currentWorks.kind,
        mediaID: this.currentWorks.id,
      };
      let res = await this.$Api(doCollect, req);

      if (res.code == 200) {
        this.currentWorks.hasCollected = true;
        this.$toast({
          message: "收藏成功",
          position: "top",
        });
      }
    },
    async cancelCollectionWorks() {
      /**
       * 取消收藏
       */
      let req = {
        kind: this.currentWorks.kind,
        mediaID: this.currentWorks.id,
      };
      let res = await this.$Api(douUcollect, req);
      if (res.code == 200) {
        this.currentWorks.hasCollected = false;
        this.$toast({
          message: "取消收藏",
          position: "top",
        });
      }
    },
    goAdv(item) {
      jumpAdv(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.comicReader {
  height: 100%;
  overflow-y: auto;

  .content {
    height: 100vh;

    .advertising {
      display: flex;
      justify-content: center;
      padding: 20px 0;
      /deep/ .vanImage {
        border-radius: 8px;
      }
      /deep/ .van-image__img {
        border-radius: 8px;
      }

      .advImg {
        width: 351px;
        height: 97.56px;
        border-radius: 6px;
        margin: 0 auto;
        background: rgb(240, 240, 244);

        /deep/ .van-image {
          border-radius: 6px;
        }

        /deep/ .van-image__img {
          border-radius: 6px;
        }
      }
    }

    .atlas {
      height: 100%;

      .atlasItem {
        width: 100%;
        height: auto;

        .previewImg {
          width: 100%;
          height: 100px;
        }
      }
    }
  }

  .Barrage {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
    .openBarrage {
      margin-right: 6px;
      img {
        width: 30px;
        height: 30px;
      }
    }
    .sendBarrage {
      img {
        width: 98px;
        height: 30px;
      }
    }
  }

  .bottomBar {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    color: rgb(0, 0, 0);
    height: 100%;
    align-items: center;
    padding: 0 12px;

    img {
      width: 20px;
      height: 20px;
    }

    .up {
      img {
        margin-right: 12px;
        display: inline-block;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .down {
      img {
        margin-left: 12px;
        display: inline-block;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  /deep/ .van-overlay {
    background: rgba(0, 0, 0, 0.1);
  }

  .headBar {
    display: flex;
    height: 44px;
    align-items: center;
    border-bottom: solid 1px rgb(43, 39, 74);

    .leftIcon {
      padding: 0 12px;
      img {
        width: 17px;
        height: 17px;
      }
    }

    .headTitle {
      width: 100%;
      height: 100%;
      // display: flex;
      // align-items: center;
      //justify-content: center;
      line-height: 40px;
      margin-right: 24px;
      font-weight: bold;
      font-size: 18px;
      color: #000000;

      span {
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .fictionCollect {
      margin-right: 24px;
      img {
        width: 25px;
        height: 25px;
        object-fit: fill;
      }
    }

    .fictionDetails {
      margin-right: 24px;
      img {
        width: 25px;
        height: 25px;
        object-fit: fill;
      }
    }

    .fictionSelect {
      margin-right: 24px;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  .chapterContent {
    .chapterTitle {
      display: flex;
      justify-content: center;
      font-size: 16px;
      color: #212121;
      font-weight: 500;
      margin-top: 53px;
      margin-bottom: 10px;
    }

    .chapterDivision {
      background: rgba(251, 209, 61, 1);
      height: 24px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      font-size: 12px;
      color: rgba(51, 51, 51, 1);
      font-weight: 500;
      .name {
        margin-left: 12px;
      }

      .btn {
        margin-right: 12px;
        display: flex;
        align-items: center;
        img {
          width: 11.7px;
          height: 11.5px;
          margin-right: 4px;
        }
      }
    }

    .chapterList {
      font-size: 14px;
      color: #9e9e9e;
      height: calc(100vh - 112px);
      overflow-y: scroll;

      .chapterItem {
        height: 57px;
        display: flex;
        align-items: center;
        padding-left: 12px;

        .activeName {
          font-size: 14px;
          font-weight: 500;
          color: #212121;
        }
      }
    }
  }
}
</style>
